import React from 'react';
import { Icon } from 'components';
import { useTranslation } from 'react-i18next';
import { withTheme, DefaultTheme } from 'styled-components';
import { PageWithTabs } from 'components/Layout';
import appRoutes from 'Router/routes';
import UsersPage from './UsersPage';
import EmployeesPage from './EmployeesPage';
import OscarPage from './OscarPage';

const SynchOscarIsEnabled = window.config.ENABLE_SYNCH_OSCAR === "true";

interface IProps {
    theme: DefaultTheme;
}

const buildRoutes = (t: any) => {
    const routes = [
        {
            tabKey: 'users',
            tabLabel: t('page:admin.users.tab'),
            path: appRoutes.admin.users.list,
            component: UsersPage,
        },
        {
            tabKey: 'employees',
            tabLabel: t('page:admin.employees.tab'),
            path: appRoutes.admin.employees.list,
            component: EmployeesPage,
        },
    ];

    if (SynchOscarIsEnabled) {
        routes.push({
            tabKey: 'oscarsync',
            tabLabel: t('page:admin.oscarSync.tab'),
            path: appRoutes.admin.oscarsync.list,
            component: OscarPage,
        });
    }

    return routes;
};

const Admin: React.FC<IProps> = ({ theme }) => {
    const { t } = useTranslation();
    const routes = buildRoutes(t);

    return (
        <PageWithTabs
            routes={routes}
            indexRoute={appRoutes.admin.index()}
            title={t('app:app.admin') || ''}
            Icon={<Icon.Admin color={theme.color.grey[6]} />}
        />
    );
};

export default withTheme(Admin);
