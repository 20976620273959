type FeatureData = {
    environment: string;
    feature_name: string;
    [key: string]: string | [string];
};

export default async function getAppConfiguration(feature_name): Promise<FeatureData> {
    const applicationId = 'foodibo';

    try {
        let uri = `${window.config.CONFIGURATION_TOOL_URL}/conf?application_id=${applicationId}`;

        const response = await window.fetch(uri, {
            method: 'get',
            headers: { 'x-api-key': window.config.CONFIGURATION_TOOL_API_KEY },
        });

        const { data } = await response.json();
        if (data.statusCode) throw Error;
        return Promise.resolve(
            data.data.filter(
                (rawData) => rawData.feature_name === feature_name && rawData.environment === window.config.FOODI_ENV
            )[0]
        );
    } catch (error) {
        return Promise.reject(error);
    }
}
